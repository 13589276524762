import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Github, Linkedin, Mail, ExternalLink, Menu, X } from 'lucide-react';

const FadeInWhenVisible = ({ children, delay = 0 }) => {
  return (
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, delay }}
    >
      {children}
    </motion.div>
  );
};

const Portfolio = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const projects = [
    {
      title: "Project 1",
      description: "A full-stack web application built with React and Node.js",
      tags: ["React", "Node.js", "MongoDB"],
      link: "https://project1.com",
      github: "https://github.com/username/project1"
    },
    {
      title: "Project 2",
      description: "Mobile-first responsive website with modern animations",
      tags: ["React", "Tailwind", "Framer Motion"],
      link: "https://project2.com",
      github: "https://github.com/username/project2"
    }
  ];

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
      {/* Navigation */}
      <motion.nav 
        className="fixed w-full backdrop-blur-md bg-gray-900/80 z-50"
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <motion.a 
              href="#home"
              className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent"
              whileHover={{ scale: 1.05 }}
            >
              Muhammad Ahsan
            </motion.a>

            <div className="hidden md:flex space-x-8">
              {["Home", "Projects", "Skills", "Contact"].map((item) => (
                <motion.a
                  key={item}
                  href={`#${item.toLowerCase()}`}
                  className="text-gray-300 hover:text-white transition-colors"
                  whileHover={{ y: -2 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {item}
                </motion.a>
              ))}
            </div>

            <motion.button 
              className="md:hidden text-white"
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </motion.button>
          </div>
        </div>
      </motion.nav>

      {/* Hero Section */}
      <section className="min-h-screen flex items-center justify-center px-4 pt-16">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center gap-12">
          <motion.div 
            className="flex-1 text-center md:text-left"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
              Hello, I'm Muhammad Ahsan
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Full Stack Developer | Problem Solver | Tech Enthusiast
            </p>
            <motion.div 
              className="flex justify-center md:justify-start space-x-6"
              variants={container}
              initial="hidden"
              animate="show"
            >
              <motion.a 
                href="https://github.com/ahsanabbasi404/" 
                className="text-gray-300 hover:text-white"
                whileHover={{ y: -4 }}
                variants={item}
              >
                <Github size={28} />
              </motion.a>
              <motion.a 
                href="https://linkedin.com" 
                className="text-gray-300 hover:text-white"
                whileHover={{ y: -4 }}
                variants={item}
              >
                <Linkedin size={28} />
              </motion.a>
              <motion.a 
                href="mailto:abbasiahsan699@gmail.com" 
                className="text-gray-300 hover:text-white"
                whileHover={{ y: -4 }}
                variants={item}
              >
                <Mail size={28} />
              </motion.a>
            </motion.div>
          </motion.div>
          
          <motion.div 
            className="flex-1"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <motion.img
              src="/personal.png"
              alt="Personal Photo"
              className="rounded-full w-64 h-64 md:w-96 md:h-96 object-cover mx-auto"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            />
          </motion.div>
        </div>
      </section>

      {/* Projects Section */}
      <section id="projects" className="py-20 px-4">
        <div className="max-w-6xl mx-auto">
          <FadeInWhenVisible>
            <h2 className="text-4xl font-bold text-center mb-12 bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
              Featured Projects
            </h2>
          </FadeInWhenVisible>

          <motion.div 
            className="grid md:grid-cols-2 gap-8"
            variants={container}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
          >
            {projects.map((project, index) => (
              <motion.div
                key={index}
                className="bg-gray-800/50 rounded-xl p-6 backdrop-blur-sm border border-gray-700/50"
                variants={item}
                whileHover={{ y: -10 }}
                transition={{ duration: 0.3 }}
              >
                <h3 className="text-xl font-semibold mb-3">{project.title}</h3>
                <p className="text-gray-300 mb-4">{project.description}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {project.tags.map((tag, tagIndex) => (
                    <span
                      key={tagIndex}
                      className="bg-blue-500/20 text-blue-300 px-3 py-1 rounded-full text-sm"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                <div className="flex space-x-4">
                  <motion.a
                    href={project.link}
                    className="flex items-center text-blue-400 hover:text-blue-300"
                    whileHover={{ x: 5 }}
                  >
                    <ExternalLink size={16} className="mr-1" /> Live Demo
                  </motion.a>
                  <motion.a
                    href={project.github}
                    className="flex items-center text-gray-300 hover:text-white"
                    whileHover={{ x: 5 }}
                  >
                    <Github size={16} className="mr-1" /> Code
                  </motion.a>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* Skills Section */}
      <section id="skills" className="py-20 px-4">
        <div className="max-w-6xl mx-auto">
          <FadeInWhenVisible>
            <h2 className="text-4xl font-bold text-center mb-12 bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
              Skills & Technologies
            </h2>
          </FadeInWhenVisible>

          <motion.div 
            className="grid grid-cols-2 md:grid-cols-4 gap-4"
            variants={container}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
          >
            {["React", "Node.js", "TypeScript", "MongoDB", "Python", "AWS", "Docker", "Git"].map((skill, index) => (
              <motion.div
                key={index}
                className="bg-gray-800/50 rounded-xl p-6 text-center backdrop-blur-sm border border-gray-700/50"
                variants={item}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                {skill}
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-20 px-4">
        <div className="max-w-6xl mx-auto">
          <FadeInWhenVisible>
            <h2 className="text-4xl font-bold text-center mb-12 bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
              Get In Touch
            </h2>
          </FadeInWhenVisible>

          <motion.div 
            className="max-w-xl mx-auto bg-gray-800/50 rounded-xl p-8 backdrop-blur-sm border border-gray-700/50"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div className="space-y-6">
              <motion.a
                href="mailto:abbasiahsan699@gmail.com"
                className="flex items-center space-x-4 text-gray-300 hover:text-white"
                whileHover={{ x: 10 }}
              >
                <Mail className="text-blue-400" />
                <span>abbasiahsan699@email.com</span>
              </motion.a>
              <motion.a
                href="https://linkedin.com"
                className="flex items-center space-x-4 text-gray-300 hover:text-white"
                whileHover={{ x: 10 }}
              >
                <Linkedin className="text-blue-400" />
                <span>LinkedIn Profile</span>
              </motion.a>
              <motion.a
                href="https://github.com"
                className="flex items-center space-x-4 text-gray-300 hover:text-white"
                whileHover={{ x: 10 }}
              >
                <Github className="text-blue-400" />
                <span>Github Profile</span>
              </motion.a>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Portfolio;